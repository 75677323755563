import React, {Component}  from 'react';
import moment from "moment";
import axios from 'axios';
import qs from "query-string";

// import {Link} from "react-router-dom";
import RingLoader from 'react-spinners/ScaleLoader';
// import Countdown from 'react-countdown-now';

import './InformePaciente.css';
import Alert from './modal/alert'


class InformePaciente extends Component {
  constructor(props){
    super(props);
    this.state = {
        cedula2:'',
        nombre2: '',
        edad2: '',
        fechad2: moment().format('YYYY-MM-DD'),
        mensajealerta:false,
        consulOn:false,
        loading:false,
        ifechaevo:[],
        cedula: '',
        nombre: '',
        edad: '',
        fechad: '',
        fechah: '',
        diagnostico: '',
        tratamiento: '',
        dias: '',
        sintoma: '',
        diase: '',
        diash: '',
        examenf: '',
        laboratorio: '',
        ecog: '',
        endoscopia: '',
        ninguno: '',
        anexos: '',
        radiologia: '',
        tac: '',
        otros: '',
        biopsia: '',
        quirurgico: '',
        medico: '',
        procedimiento: '',
        intensidad: '',
        tomar: '',
        arazon: '',
        diast: '',
        indica: '',
        dee: '',
        cantidadde: '',
        tipoinforme:'',
        firma:'',

    }
  }
  onChange(e) {
    //console.log(e.target.name)
    this.setState({
        [e.target.name]: e.target.value
    })
  }

limpiar=()=>{
    this.setState({
        cedula2:'',
        nombre2: '',
        edad2: '',
        fechad2: moment().format('YYYY-MM-DD'),
        mensajealerta:false,
        consulOn:false,
        ifechaevo: [],
        cedula: '',
        nombre: '',
        edad: '',
        fechad: '',
        fechah: '',
        diagnostico: '',
        tratamiento: '',
        dias: '',
        sintoma: '',
        diase: '',
        diash: '',
        examenf: '',
        laboratorio: '',
        ecog: '',
        endoscopia: '',
        ninguno: '',
        anexos: '',
        radiologia: '',
        tac: '',
        otros: '',
        biopsia: '',
        quirurgico: '',
        medico: '',
        procedimiento: '',
        intensidad: '',
        tomar: '',
        arazon: '',
        diast: '',
        indica: '',
        dee: '',
        cantidadde: '',
        tipoinforme:'',
        firma:'',
    })
}

imprimirReferidor=()=>{
    let data = new FormData();
    data.append('nombre', this.state.medico);
    data.append('colega', this.state.tratamiento);
    data.append('referir', this.state.nombre);
    data.append('presenta', this.state.diagnostico);
    data.append('practicado', this.state.sintoma);
    data.append('desde', this.state.fechad);
    data.append('firma', this.state.firma);
    axios({
        method:'post',
        url:'https://fehensa.com/consultorio2/fpdf/reportes/referidoPHP.php',
        responseType:'arraybuffer',
        data: data
      })
        .then(function(response) {
          let blob = new Blob([response.data], { type:   'application/pdf' } )
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
      });
}

imprimirestupefacientes=()=>{
    let data = new FormData();
    data.append('cedu', this.state.cedula);
    data.append('nombre', this.state.nombre);
    data.append('diagnostico', this.state.diagnostico);
    data.append('intensidad', this.state.intensidad);
    data.append('tomar', this.state.tomar);
    data.append('arazon', this.state.arazon);
    data.append('tratamiento', this.state.diast);
    data.append('indica', this.state.indica);
    data.append('de', this.state.dee);
    data.append('cantidad', this.state.cantidadde);
    data.append('desde', this.state.fechad);
    data.append('firma', this.state.firma);
    axios({
        method:'post',
        url:'https://fehensa.com/consultorio2/fpdf/reportes/estupefacientePHP.php',
        responseType:'arraybuffer',
        data: data
      })
        .then(function(response) {
          let blob = new Blob([response.data], { type:   'application/pdf' } )
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
           let link = document.createElement('a')
           link.href = window.URL.createObjectURL(blob)
           link.download = 'reporte.pdf'
           link.click()
         
      });
}

imprimirconstanciamedica=()=>{
    let data = new FormData();
    data.append('cedu', this.state.cedula);
    data.append('nombre', this.state.nombre);
    data.append('edad', this.state.edad);
    data.append('diagnostico', this.state.diagnostico);
    data.append('desde', this.state.fechad);
    data.append('firma', this.state.firma);

    axios({
        method:'post',
        url:'https://fehensa.com/consultorio2/fpdf/reportes/constanciamePHP.php',
        responseType:'arraybuffer',
        data: data
      })
        .then(function(response) {
          let blob = new Blob([response.data], { type:   'application/pdf' } )
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
           let link = document.createElement('a')
           link.href = window.URL.createObjectURL(blob)
           link.download = 'reporte.pdf'
           link.click()
         
      });    
}

imprimirreposomedico=()=>{
    let data = new FormData();
    data.append('cedu', this.state.cedula);
    data.append('nombre', this.state.nombre);
    data.append('edad', this.state.edad);
    data.append('diagnostico', this.state.diagnostico);
    data.append('tratamiento', this.state.tratamiento);
    data.append('dias', this.state.dias);
    data.append('desde', this.state.fechad);
    data.append('hasta', this.state.fechah);
    data.append('firma', this.state.firma);

    axios({
        method:'post',
        url:'https://fehensa.com/consultorio2/fpdf/reportes/reposomePHP.php',
        responseType:'arraybuffer',
        data: data
      })
        .then(function(response) {
          let blob = new Blob([response.data], { type:   'application/pdf' } )
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'reporte.pdf'
          link.click()
         
      });
}
 
imprimirinforme=()=>{
        let data = new FormData();
        data.append('cedu', this.state.cedula);
        data.append('nombre', this.state.nombre);
        data.append('edad', this.state.edad);
        data.append('nota', this.state.diagnostico);
        data.append('desde', this.state.fechad);
        data.append('firma', this.state.firma);
        data.append('tipoI', "INFORME MEDICO "+this.state.tipoinforme);
        axios({
            method:'post',
            url:'https://fehensa.com/consultorio2/fpdf/reportes/rinfegrePHP.php',
            responseType:'arraybuffer',
            data: data
        })
            .then(function(response) {
            let blob = new Blob([response.data], { type:   'application/pdf' } )
            var fileURL = URL.createObjectURL(blob);
            window.open(fileURL);
               let link = document.createElement('a')
               link.href = window.URL.createObjectURL(blob)
               link.download = 'reporte.pdf'
               link.click()
        });
}

imprimirinformeingreso=()=>{

    let data = new FormData();
    data.append('cedu', this.state.cedula);
    data.append('nombre', this.state.nombre);
    data.append('edad', this.state.edad);
    data.append('diagnostico', this.state.diagnostico);
    data.append('desde', this.state.fechad);
    data.append('sintoma', this.state.sintoma);
    data.append('tevo', this.state.diase);
    data.append('thosp', this.state.diash);
    data.append('fisico', this.state.examenf);
    data.append('labo', this.state.laboratorio);
    data.append('eco', this.state.ecog);
    data.append('endo', this.state.endoscopia);
    data.append('ninguno', this.state.ninguno);
    data.append('anexo', this.state.anexos);
    data.append('radio', this.state.radiologia);
    data.append('tac', this.state.tac);
    data.append('otros', this.state.otros);
    data.append('biopsia', this.state.biopsia);
    data.append('quiru', this.state.quirurgico);
    data.append('medico', this.state.medico);
    data.append('proce', this.state.procedimiento);
    data.append('firma', this.state.firma);



    axios({
        method:'post',
        url:'https://fehensa.com/consultorio2/fpdf/reportes/repoingrePHP.php',
        responseType:'arraybuffer',
        data: data
      })
        .then(function(response) {
          let blob = new Blob([response.data], { type:   'application/pdf' } )
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
           let link = document.createElement('a')
           link.href = window.URL.createObjectURL(blob)
           link.download = 'reporte.pdf'
           link.click()
         
      });
}
actualizar=(res)=>{
    this.setState({
        cedula: res.data.variable1,
        nombre: res.data.variable2,
        edad: res.data.variable3,
        fechad: res.data.variable4,
        fechah: res.data.variable5,
        diagnostico: res.data.variable6,
        tratamiento: res.data.variable7,
        dias: res.data.variable8,
        sintoma: res.data.variable9,
        diase: res.data.variable10,
        diash: res.data.variable11,
        examenf: res.data.variable12,
        laboratorio: res.data.variable13,
        ecog: res.data.variable14,
        endoscopia: res.data.variable15,
        ninguno: res.data.variable16,
        anexos: res.data.variable17,
        radiologia: res.data.variable18,
        tac: res.data.variable19,
        otros: res.data.variable20,
        biopsia: res.data.variable21,
        quirurgico: res.data.variable22,
        medico: res.data.variable23,
        procedimiento: res.data.variable24,
        intensidad: res.data.variable25,
        tomar: res.data.variable26,
        arazon: res.data.variable27,
        diast: res.data.variable28,
        indica: res.data.variable29,
        dee: res.data.variable30,
        cantidadde: res.data.variable31,
        tipoinforme: res.data.variable32,
        firma: res.data.variable33,
    })
}

buscarinforme=(e)=>{
    //console.log("paso: "+e)
    axios.post('https://fehensa.com/consultorio2/informepacientesPHP.php/?codigo=' + e + '&boton=ConsultarDatos')
    .then(res => {
            if (res.data) {
                this.actualizar(res);
                console.log(this.state.tipoinforme);
                if (this.state.tipoinforme==='REPOSOMEDICO'){this.imprimirreposomedico()}
                if (this.state.tipoinforme==='CONSTANCIAMEDICA'){this.imprimirconstanciamedica()}
                if (this.state.tipoinforme==='INGRESO'){this.imprimirinformeingreso()}
                if (this.state.tipoinforme==='DE EGRESO'){this.imprimirinforme()}
                if (this.state.tipoinforme==='EVOLUTIVO'){this.imprimirinforme()}
                if (this.state.tipoinforme==='QUIRURGICO'){this.imprimirinforme()}
                if (this.state.tipoinforme==='ESTUPEFACIENTES'){this.imprimirestupefacientes()}
                if (this.state.tipoinforme==='REFERIDOR'){this.imprimirReferidor()}
                this.setState({loading:false})
            } 
        })
}

buscar=(e)=>{
    if (e!=="") {
            axios.post('https://fehensa.com/consultorio2/informepacientesPHP.php/?cedula=' + e + '&boton=Consultar')
        //console.log('https://fehensa.com/consultorio2/informepacientesPHP.php/?cedula=' + this.state.cedula2 + '&boton=Consultar')
        .then(res => {
            if (res.data) {
                let xfechaevo = res.data
                this.setState({
                    ifechaevo:xfechaevo,
                });
            } 
        })
    }
}

buscar2=(e)=>{
    if (e!=="") {
            axios.post('https://fehensa.com/consultorio2/informepacientesPHP.php/?cedula=' + e + '&boton=Consultar2')
        //console.log('https://fehensa.com/consultorio2/informepacientesPHP.php/?cedula=' + this.state.cedula2 + '&boton=Consultar')

        .then(res => {
            if (res.data) {
                this.setState({
                    cedula2: res.data.variable1,
                    nombre2: res.data.variable2,
                    edad2: res.data.variable3,
                    consulOn:true,
                });
            } 
        })
    }
}

spineractivar(e){
    if (this.state.loading===true){
      this.setState({
        loading:false,
      })
    }else{
      this.setState({
        loading:true,
      })
      if (parseInt(e)>0){
        this.buscarinforme(e);
      }

    }
  }
  componentDidMount(){
    const values = qs.parseUrl(window.location.search);
    const xcedu = values.query['item'];
    this.buscar(xcedu);  
    this.buscar2(xcedu);  
  }

handleClose = () => {
    this.setState({ mensajealerta: false })
}

  render(){
   
      return(
        <div className="conte row">  
            <div className="cont-quimioc col-12">

                {this.state.mensajealerta === true &&
                    <Alert
                    tituloMensaje={this.state.tituloM}
                    cuerpoMensaje={this.state.cuerpoM}
                    open={this.state.mensajealerta}
                    handleClose={this.handleClose}
                    />
                }  

                {this.state.loading === true &&
                    <div className="cont-spinner row">
                        <div className='spinner col-6'>
                        <RingLoader
                            clasName="spinner"
                            sizeUnit={"px"}
                            size={160}
                            width={19}
                            radius={20}
                            height={160}
                            color={'#48e120'}
                            loading={this.state.loading}
                        />
                        </div>
                    </div>
                }

                <div className="row">
                    <div className="cont-tituloquimioc col-12">    
                        <span className="tituloquimioc">Imprimir Informe de Pacientes</span>
                        {/* <Link to="#"><span className="titulo-cerrarquimioc">X</span></Link> */}
                    </div>
                </div>
                <div className='row'>
                    {this.state.consulOn === true &&
                        <div className="cont-opcion col-4">
                            <label className="opcion">Cedula del Paciente</label>
                            <input
                                disabled={this.state.consulto}
                                className="form-control"
                                type="text"
                                name="cedula2"
                                id="cedula2"
                                autoComplete="off"
                                pattern="[0-9] required"
                                readOnly={this.state.consulOn}
                                value={this.state.cedula2}
                                onChange={this.onChange.bind(this)}
                                onKeyPress={event => { if (event.key === 'Enter') {this.spineractivar()} }}
                            />
                        </div>
                    }   
                    {/* {this.state.consulOn === false &&
                        <button className="botongr" onClick={this.spineractivar.bind(this)}>Buscar</button>  
                    } */}
                    {this.state.consulOn === true &&
                        <div className="cont-opcion col-1">
                            <label className="opcion" >Edad.</label>
                            <input
                                className="form-control"
                                type="text"
                                name="edad2"
                                id="edad2"
                                value={this.state.edad2}
                                readOnly={true}
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                    }    
                    {this.state.consulOn === true &&
                        <div className="cont-opcion col-6">
                            <label className="opcion" >Nombre.</label>
                            <input
                                className="form-control"
                                type="text"
                                name="nombre2"
                                id="nombre2"
                                autoComplete="off"
                                value={this.state.nombre2}
                                readOnly={true}
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                    }    
                </div>    
                {this.state.consulOn === true &&
                    <div className="row">
                        <div className="cont-tituloquimioc col-12">    
                            <span className="tituloquimioc">Elija el Informe a Imprimir.</span>
                        </div>
                        <div className="cont-tituloquimioc col-12">    
                            <span className="tituloquimioc">Haciendo click sobre el renglon de la tabla</span>
                        </div>
                        
                    </div>
                }
                {this.state.consulOn === true &&
                    <div className="cont-evograi row">
                        <div className="evograi col-5">
                            {/* <span className="titulo-listevoi">Informes a Imprimir</span> */}
                            <div className="cont-listevogra row">
                                <div className="listevograi col-12">
                                <table className="evotabla2grai">
                                    <tbody className="evocuerpo1">
                                    {this.state.ifechaevo.map((item, i)=>{
                                        return<tr className={i%2 === 0 ? "odd" : ""} key={item.variable1} onClick={this.spineractivar.bind(this,item.variable1)}><td>{item.variable2}</td>
                                            <td width="30px">{item.variable3}</td></tr>                                
                                                
                                    })}
                                    </tbody>
                                </table>
                                </div>    
                            </div>
                        </div>  
                    </div>
                }       
                {/* {this.state.consulOn === true &&
                    <div className="row">
                        <div className="botonesgeneral col-12">
                            <button className="botongr" onClick={this.limpiar.bind(this)}>Realizar Otra Consulta</button>
                        </div>
                    </div>
                } */}
            </div>
        </div>
    )
  }
}
export default InformePaciente;  