import React from 'react';
import Paciente from './InformePaciente'

function App() {
  return (
    
      <div className="App">
        <div className="card">
          <Paciente />
        </div>
      </div>
   

  );
}

export default App;
